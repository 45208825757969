import React, {useCallback, useEffect, useState} from 'react';
import schema_columns from "../resources/schema_columns";
import axios from "axios";
import Login from "./dhis/login";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import csvJSON from "../assets/csvJSON";
import DHIS from "./dhis";
import Button from "@mui/material/Button";


const theme = createTheme();

function WDCHome(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [authKey,setAuthKey] = useState('');
    const [baseUrl,setBaseUrl] = useState('');
    const [userOrgs,setUserOrgs] = useState([]);
    const [deGroupParam, setDeGroupParam] = useState(null);
    const [indGroupParam, setINDGroupParam] = useState(null);
    const [deParam, setDEParam] = useState(null);
    const [indParam, setINDParam] = useState(null);
    const [progIndParam, setProgINDParam] = useState(null);
    const [eventItemsParam, setEventItemsParam] = useState(null);
    const [peParam, setPEParam] = useState(null);
    const [ouParam, setOUParam] = useState(null);
    const [wdcData, setWDCData] = useState({
        user_org:[],
        base_url:"",
        access_token:"",
        analytics_url:"",
        dxParam:[],
        deGroupParam:"",
        deParam:"",
        peParam:"",
       // ouParam:'&dimension=ou:ImspTQPwCqd',
        ouParam:'',
        layoutParam:'&tableLayout=true&rows=pe;dx;ou&skipRounding=false&completedOnly=false'
    });

    const getConnectionData = () => {
        wdcData.schema = {
            id: "dhis2data",
            alias: "DHIS2 Data",
            columns: schema_columns()
        };
        wdcData.user_org=userOrgs;
        wdcData.access_token = authKey;
        wdcData.base_url = baseUrl;
        wdcData.analytics_url="/api/analytics.csv?";
        wdcData.ouParam=ouParam
        wdcData.peParam=peParam
        wdcData.deParam=deParam
        wdcData.dxParam=[
            deParam,
            deGroupParam,
            indGroupParam,
            indParam,
            progIndParam,
            eventItemsParam
        ]
        wdcData.deGroupParam=deGroupParam

        setWDCData(wdcData);
        return wdcData;
    }



    const loadWDC = () => {

        window.tableau.connectionData = JSON.stringify(getConnectionData());
        window.tableau.connectionName = "dhis2_data";
        window.tableau.submit(); // This sends the connector object to Tableau
    }
    return (
        <div>
            {!loggedIn &&<Login setLoggedIn={setLoggedIn} setAuthKey={setAuthKey} setBaseUrl={setBaseUrl} setUserOrgs={setUserOrgs} />}
            {loggedIn && <>
                <ThemeProvider theme={theme} >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent:'center',
                            width:'100%',
                        }}
                    >
                        <Container component="main"
                                   sx={{
                                       marginTop: 1,
                                       marginBottom:1,
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'center',
                                       width: '75%'
                                   }}
                        >
                            <DHIS access_token={authKey}
                                   base_url={baseUrl}
                                   setDeGroupParam={setDeGroupParam}
                                   setDEParam={setDEParam}
                                   setINDGroupParam={setINDGroupParam}
                                   setINDParam={setINDParam}
                                   setProgINDParam={setProgINDParam}
                                   setEventItemsParam={setEventItemsParam}
                                   setPEParam={setPEParam}
                                   setOUParam={setOUParam}
                            />
                            <Button
                                onClick={() =>  loadWDC()}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Import Data
                            </Button>
                        </Container>

                    </Box>
                </ThemeProvider>

            </>}

        </div>
    );
}

export default WDCHome;

