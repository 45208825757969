import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DHIS2LOGO from '../../assets/dhis2.png';
import TABLEAULOGO from '../../assets/tableau.png';
import axios from "axios";
import {Grid} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Alert from '@mui/material/Alert';


const theme = createTheme();


function Login(props) {
    const [urlError, setUrlError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState('Wrong username or password!')
    const login = (credentials) =>{
        let authKey = btoa(`${credentials.username}:${credentials.password}`);
        axios.get(credentials.url+'/api/me',
            {
                headers: {
                    "Authorization": `Basic ${authKey}`
                }
            }).then((response)=>{
                if(response.status === 200 && response.data.authorities && response.data.authorities.length >= 1){
                    props.setLoggedIn(true);
                    props.setAuthKey(authKey);
                    props.setBaseUrl(credentials.url)
                    props.setUserOrgs(response.data.organisationUnits)
                    setLoginError(false)
                }else {
                    setLoginError(true);
                    setLoginErrorMessage('Kindly contact you administrator. Login was successful ')
                }
        }).catch((e)=>{
            setLoginError(true);
            setLoginErrorMessage('Wrong username or password!')
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        login({
            username: data.get('username'),
            password: data.get('password'),
            url:data.get('url')
        });
    };
    const isValidHttpUrl = (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Avatar sx={{ width: 140, height: 140, backgroundColor:'#fff',border:'1px solid #ddd' }}>
                                <img src={DHIS2LOGO} alt="Logo" width={130} height={130} />;
                            </Avatar>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex',justifyContent:'center'}}>
                            <ArrowForwardIcon sx={{fontSize:100,color:'#0080D4',marginTop:'20px', transform: 'scale(0.9)'}} />
                        </Grid>
                        <Grid item xs={4}>
                            <Avatar sx={{ width: 140, height: 140, backgroundColor:'#fff',border:'1px solid #ddd' }}>
                                <img src={TABLEAULOGO} alt="Logo" width={130} height={130} />;
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Typography component="h1" variant="h5" style={{color:'#014d88',fontSize:'2rem',marginTop:'10px'}}>
                        Connect to DHIS2 instance
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            error={urlError}
                            margin="normal"
                            required
                            fullWidth
                            id="url"
                            label="url"
                            name="url"
                            autoComplete="url"
                            onInput={(e)=>{
                                setUrlError(!isValidHttpUrl(e.target.value))
                            }}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        {loginError &&
                            <Alert severity="error">{loginErrorMessage}</Alert>
                        }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login;
