import _ from 'lodash';

const csvJSON = (csv) =>{
    let columns = ["periodid","periodname","periodcode","organisationunitid","organisationunitname","dataid","dataname","Total"]
    var lines=csv.split(/\n/);
    var result = [];
    var headers=lines[0].split(",");
    headers[headers.length-1] = headers[headers.length-1].replace('\r','');
    for(var i=1;i<lines.length-1;i++){
        var obj = {};
        var currentline=lines[i].split(",");

        for(var j=0;j<headers.length;j++){
            if(columns.includes(headers[j])){
                obj[headers[j]] = currentline[j];
            }
        }
        result.push(obj);

    }

    //return result; //JavaScript object
    return result; //JSON
}
export default csvJSON;
