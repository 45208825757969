import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from "axios";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {makeStyles} from "@mui/styles";
import _ from 'lodash';


//override css
const useStyles = makeStyles(theme => ({
    root: {
        border:'2px solid #eee',
        padding:'5px',
        '& .react-dropdown-tree-select .dropdown .dropdown-content': {
            position:'relative',
            marginTop:'-410px',
            width:'470px',
            padding:'10px'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger':{
            marginLeft:'520px',
            width:'400px',
            minHeight:'400px'
        },
        '& .tag-item .search':{
            display:'none'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after':{
            display:'none'
        }
    },
}))

function Indicatorgroups(props) {
    const classes = useStyles()
    const [indicatorGroups, setIndicatorGroups] = useState([])


    const assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                assignObjectPaths(node, node.path);
            }
        });
    };
    const fetchIndicatorGroups = () =>{
        axios.get(props.base_url+'/api/indicatorGroups.json?fields=id,displayName,path,children::isNotEmpty,memberCount,indicators[name,id]&paging=false',
            {
                headers: {
                    "Authorization": `Basic ${props.access_token}`
                }
            }).then((response)=>{
            if(response.status === 200){
                if(response.data.indicatorGroups.length > 0){
                    let temp = [];
                    response.data.indicatorGroups.forEach(group =>{

                        let children = [];

                        group.indicators.forEach(child =>{
                            children.push({
                                label:child.name,
                                id:child.id,
                                level:'child'
                            })
                        })
                        temp.push({
                            "label":group.displayName,
                            "id":group.id,
                            level:"group",
                            children: children
                        })
                    })
                    setIndicatorGroups(temp)
                }
                //setDEGroups(response.data.dataElementGroups)
            }
        })
    }

    useEffect(()=>{
        fetchIndicatorGroups()
    },[])

    const onChange = (currentNode, selectedNodes) => {
        let indGroupParam =[]
        let indParam =  _.map(_.filter(selectedNodes, {level:'child'}), 'id');
        _.map(_.filter(selectedNodes, {level:'group'}), 'id').forEach(dx =>{
            indGroupParam.push('IN_GROUP-'+dx)
        });

        props.setINDGroupParam(indGroupParam.length > 0?'&dimension=dx:'+_.join(indGroupParam,';'):null);
        props.setINDParam(indParam.length > 0?'&dimension=dx:'+_.join(indParam,';'):null);
    };


    const DropDownTreeSelect = useMemo(() => {
        return (
            <DropdownTreeSelect
                data={indicatorGroups}
                onChange={onChange}
                className="bootstrap-demo"
                showDropdown="initial"
            />
        )
    }, [indicatorGroups])

    return (
        <div className={classes.root}>
            {DropDownTreeSelect}
        </div>
    )
}

export default Indicatorgroups;
