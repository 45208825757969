import axios from "axios";
import csvJSON from "../assets/csvJSON";

const tableau = window.tableau;

/**
 * Wrapper for the global tableau object
 */

const init = () => {
    const tableauConnector = tableau.makeConnector();

    tableauConnector.getSchema = function(schemaCallback) {
        const connectionData = JSON.parse(tableau.connectionData);
        window.tableau.log('getSchema - connectionData: ', tableau.connectionData); //debug
        schemaCallback([connectionData.schema]);
    };

    tableauConnector.getData = function(table, doneCallback) {
        let dataTable = [];
        let connectionData = JSON.parse(window.tableau.connectionData);
        let promises = [];
        for (let i = 0; i <= connectionData.dxParam.length-1; i++) {
            if(connectionData.dxParam[i] !== null){
               let fetchUrl = connectionData.base_url+''+connectionData.analytics_url+''+connectionData.peParam+''+connectionData.ouParam+''+connectionData.dxParam[i]+''+connectionData.layoutParam
                promises.push(
                    new Promise(function (resolve, reject) {
                        axios.get(fetchUrl,
                            {
                                headers: {
                                    "Authorization": `Basic ${connectionData.access_token}`
                                }
                            }).then((response)=>{
                            dataTable.push(...csvJSON(response.data));
                            resolve();
                        })
                    })

                )
            }

        }
        Promise.all(promises).then(() => {
            table.appendRows(dataTable);
            doneCallback();
        });
    };

    tableau.registerConnector(tableauConnector);
};


export { init };
