import React, {useState} from 'react';
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import _ from 'lodash';


const periodItems = ["TODAY","YESTERDAY","LAST_3_DAYS","LAST_7_DAYS","LAST_14_DAYS","LAST_30_DAYS","LAST_60_DAYS","LAST_90_DAYS","LAST_180_DAYS","LAST_12_MONTHS","LAST_12_WEEKS","LAST_2_SIXMONTHS","LAST_3_MONTHS","LAST_4_QUARTERS","LAST_4_WEEKS","LAST_52_WEEKS","LAST_5_FINANCIAL_YEARS","LAST_5_YEARS","LAST_6_BIMONTHS","LAST_BIMONTH","LAST_FINANCIAL_YEAR","LAST_MONTH","LAST_QUARTER","LAST_SIX_MONTH","LAST_WEEK","LAST_YEAR","MONTHS_LAST_YEAR","MONTHS_THIS_YEAR","QUARTERS_LAST_YEAR","QUARTERS_THIS_YEAR","THIS_BIMONTH","THIS_FINANCIAL_YEAR","THIS_MONTH","THIS_QUARTER","THIS_SIX_MONTH","THIS_WEEK","THIS_YEAR"];
let peItems=[];
function Periods(props) {

    const peDimensions = (e, item) =>{
        if (e.target.checked){
            peItems.push(item)
        }else{
            _.remove(periodItems, item)
        }
        props.setPEParam(peItems.length > 0?'&dimension=pe:'+_.join(peItems,';'):null);
    }
    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <Typography id="demo-radio-buttons-group-label"  style={{color:'#fff',fontWeight:'bold',marginTop:'10px',padding:'10px', backgroundColor:"rgb(23, 162, 184)",fontSize:'18px'}}>Select Date</Typography>
            {periodItems.map((item,index)=>{
                return <FormControlLabel key={item} control={<Checkbox onChange={(e)=>peDimensions(e,item)}/>} label={<Typography style={{fontSize:'14px',fontWeight:'bold',color:'#014d88'}}>{item}</Typography>} />

            })}
            <FormGroup row={true}>

            </FormGroup>
        </Box>

    );
}

export default Periods;
