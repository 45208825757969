import React, {useEffect, useState} from 'react';
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import TreeNode from "./treenode";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import _ from 'lodash';





function Orgunits(props) {
    const [orgunits, setOrgunits] = useState([]);
    const [treeOrgunits, setTreeOrgunits]=useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [tree, setTree] = useState(
        new TreeNode({
            id: "1",
            name: "src",
            children: []
        })
    );


    const fetchOrgunits = () =>{
        axios.get(props.base_url+'/api/organisationUnits.json?fields=id,displayName,children::isNotEmpty,parent,level&filter=level:in:[1,2,3]&paging=false',
            {
                headers: {
                    "Authorization": `Basic ${props.access_token}`
                }
            }).then((response)=>{
            if(response.status === 200){
                if(response.data.organisationUnits.length > 0){
                    let temp = [];
                    response.data.organisationUnits.forEach(org =>{
                        temp.push({
                            "name":org.displayName,
                            "id":org.id,
                            level:org.level,
                            parentid:org.parent != undefined ? org.parent.id:null
                        })
                    })
                    setOrgunits(temp)
                    const result = _.orderBy(temp,'level','asc').reduce((acc, item) => {
                        acc.set(item.id, item)
                        /*const parent = item.parentid  === null
                            ? acc.get('root')
                            : (acc.get(item.parentid).children ??= [])*/
                        let parent = [];
                        if(item.parentid  === null){
                            parent = acc.get('root')
                        }else{
                            if(acc.get(item.parentid).children === undefined || acc.get(item.parentid).children === null){
                               // console.log(acc.get(item.parentid).children.length)
                                acc.get(item.parentid).children = [];
                            }else{
                                parent = acc.get(item.parentid).children;
                            }
                            //parent = acc.get(item.parentid).children ??= [];

                        }
                        parent.push(item)

                        return acc
                    }, new Map([['root', []]])).get('root')

                    setTreeOrgunits([...result])
                }
                //setDEGroups(response.data.dataElementGroups)
            }
        })
    }

    useEffect(()=>{
        fetchOrgunits();
    },[]);


    function getChildById(node, id,parent_level) {
        let array = [];
        function getAllChild(nodes = null) {
            if (nodes === null || nodes.level >parent_level+1) return [];
            array.push(nodes.id);
            if (nodes.level <= parent_level+1 && Array.isArray(nodes.children)) {
                nodes.children.forEach(node => {
                    array = [...array, ...getAllChild(node)];
                    array = array.filter((v, i) => array.indexOf(v) === i);
                });
            }
            return array;
        }

        function getNodeById(nodes, id) {
            if (nodes.id === id ) {
                return nodes;
            } else if ( nodes.level <= parent_level+1 && Array.isArray(nodes.children)) {
                let result = null;
                nodes.children.forEach(node => {
                    if (!!getNodeById(node, id)) {
                        result = getNodeById(node, id);
                    }
                });
                return result;
            }

            return null;
        }
        let selected = getAllChild(getNodeById(node, id));
        props.setOUParam(selected.length > 0?'&dimension=ou:'+_.join(selected ,';'):null);
        return selected;
    }

    function getOnChange(checked, nodes) {

        const allNode= getChildById(treeOrgunits[0], nodes.id, nodes.level);
        let array = checked
            ? [...selected, ...allNode]
            : selected.filter(value => !allNode.includes(value));

        array = array.filter((v, i) => array.indexOf(v) === i);

        setSelected(array);
    }

    const renderTree = (nodes) => (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selected.some(item => item === nodes.id)}
                            onChange={event =>
                                getOnChange(event.currentTarget.checked, nodes)
                            }
                            onClick={e => e.stopPropagation()}
                        />
                    }
                    label={<>{nodes.name}</>}
                    key={nodes.id}
                />
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map(node => renderTree(node))
                : null}
        </TreeItem>
    );


    return (
        <Box component="form" noValidate sx={{ mt: 1 }}>
            <Typography id="demo-radio-buttons-group-label"  style={{color:'#fff',fontWeight:'bold',marginTop:'10px',padding:'10px', backgroundColor:"rgb(23, 162, 184)",fontSize:'18px', marginBottom:'5px'}}>Select Organisation Units</Typography>
            {treeOrgunits.length >0 &&
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    {renderTree(treeOrgunits[0])}
                </TreeView>
            }

        </Box>

    );
}

export default Orgunits;
