import React from "react";
import {
    MemoryRouter as Router,
     Routes as Switch,
    Route,
} from "react-router-dom";
import './App.css';
import WDCHome from "./component/WDCHome";

function App() {
  return (
      <Router>
          <div>
              <Switch>
                  <Route exact path="/" element={<WDCHome/>}/>
              </Switch>
          </div>
      </Router>
  );
}
export default App;
