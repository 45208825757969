import React, {useState} from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import DHIS2LOGO from "../../assets/dhis2.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Orgunits from "./orgunits";
import Dataelementgroups from "./dataelementgroups";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import Indicatorgroups from "./indicatorgroups";
import Programindicatorsgroup from "./programindicatorsgroup";
import Eventitems from "./eventitems";
import Periods from "./periods";

const theme = createTheme();
const periods = ["LAST_12_MONTHS","LAST_12_WEEKS","LAST_2_SIXMONTHS","LAST_3_MONTHS","LAST_4_QUARTERS","LAST_4_WEEKS","LAST_52_WEEKS","LAST_5_FINANCIAL_YEARS","LAST_5_YEARS","LAST_6_BIMONTHS","LAST_BIMONTH","LAST_FINANCIAL_YEAR","LAST_MONTH","LAST_QUARTER","LAST_SIX_MONTH","LAST_WEEK","LAST_YEAR","MONTHS_LAST_YEAR","MONTHS_THIS_YEAR","QUARTERS_LAST_YEAR","QUARTERS_THIS_YEAR","THIS_BIMONTH","THIS_FINANCIAL_YEAR","THIS_MONTH","THIS_QUARTER","THIS_SIX_MONTH","THIS_WEEK","THIS_YEAR"];

function ExpandMoreIcon() {
    return null;
}

function Index(props) {
    const[dePage,setDEPage] = useState(false)
    const[indicatorPage,setIndicatorPage] = useState(false)
    const[programPage,setProgramPage] = useState(false);
    const[eventPage,setEventPage] = useState(false);

    return (
            <Box  noValidate sx={{ mt: 1 }}>
                <FormGroup row={true}>
                    <FormLabel id="demo-radio-buttons-group-label"  style={{color:'rgb(23, 162, 184)',fontWeight:'bold',marginTop:'10px',paddingRight:'10px'}}>Data Type</FormLabel>
                    <FormControlLabel control={<Checkbox onClick={()=>setDEPage(!dePage)}/>} label="Data elements" style={{color:'#014d88',fontWeight:'bold'}} />
                    <FormControlLabel control={<Checkbox onClick={()=>setIndicatorPage(!indicatorPage)}/>} label="Indicators" style={{color:'#014d88',fontWeight:'bold'}}/>
                    <FormControlLabel control={<Checkbox onClick={()=>setProgramPage(!programPage)}/>} label="Program Indicators" style={{color:'#014d88',fontWeight:'bold'}} />
                    <FormControlLabel control={<Checkbox onClick={()=>setEventPage(!eventPage)}/>} label="Event Items" style={{color:'#014d88',fontWeight:'bold'}} />
                </FormGroup>
                {/*<Orgunits access_token={props.access_token} base_url={props.base_url}/>*/}
                {/*{dePage && <Dataelementgroups access_token={props.access_token} base_url={props.base_url}/>}*/}
                {dePage &&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" component="h6" style={{color:'rgb(23, 162, 184)',fontWeight:'bold',marginTop:'10px',paddingRight:'10px'}}>Select Data Elements</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Dataelementgroups access_token={props.access_token} base_url={props.base_url} setDeGroupParam={props.setDeGroupParam} setDEParam={props.setDEParam}/>
                        </AccordionDetails>
                    </Accordion>
                }

                {indicatorPage &&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" component="h6" style={{color:'rgb(23, 162, 184)',fontWeight:'bold',marginTop:'10px',paddingRight:'10px'}}>Select Indicators</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Indicatorgroups
                                access_token={props.access_token}
                                base_url={props.base_url}
                                setINDGroupParam={props.setINDGroupParam}
                                setINDParam={props.setINDParam}
                            />
                        </AccordionDetails>
                    </Accordion>
                }
                {programPage &&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" component="h6" style={{color:'rgb(23, 162, 184)',fontWeight:'bold',marginTop:'10px',paddingRight:'10px'}}>Select Program Indicators</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Programindicatorsgroup access_token={props.access_token} base_url={props.base_url} setProgINDParam={props.setProgINDParam}/>
                        </AccordionDetails>
                    </Accordion>
                }
                {eventPage &&
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" component="h6" style={{color:'rgb(23, 162, 184)',fontWeight:'bold',marginTop:'10px',paddingRight:'10px'}}>Select Event Items</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Eventitems access_token={props.access_token} base_url={props.base_url} setEventItemsParam={props.setEventItemsParam}/>
                        </AccordionDetails>
                    </Accordion>
                }

                <Periods setPEParam={props.setPEParam}/>
                <Orgunits access_token={props.access_token} base_url={props.base_url} setOUParam={props.setOUParam}/>
            </Box>
    );
}

export default Index;
