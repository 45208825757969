import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from "axios";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {makeStyles} from "@mui/styles";
import _ from 'lodash';
import csvJSON from "../../assets/csvJSON";


//override css
const useStyles = makeStyles(theme => ({
    root: {
        border:'2px solid #eee',
        padding:'5px',
        '& .react-dropdown-tree-select .dropdown .dropdown-content': {
            position:'relative',
            marginTop:'-410px',
            width:'470px',
            padding:'10px'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger':{
            marginLeft:'520px',
            width:'400px',
            minHeight:'400px'
        },
        '& .tag-item .search':{
            display:'none'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after':{
            display:'none'
        }
    },
}))
function Programindicatorsgroup(props) {
    const classes = useStyles();
    const [programs, setPrograms] = useState([])


    const assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                assignObjectPaths(node, node.path);
            }
        });
    };

    const fetchPrograms = async () =>{
        axios.get(props.base_url+'/api/programs.json?fields=id,displayName&paging=false',
            {
                headers: {
                    "Authorization": `Basic ${props.access_token}`
                }
            }).then((response)=>{
            if(response.status === 200){
                if(response.data.programs.length > 0){
                    let promises = [];
                    let temp = [];

                    response.data.programs.forEach(prog =>{

                        promises.push(
                            new Promise(function (resolve, reject) {
                                axios.get(props.base_url+''+`/api/dataItems?fields=id,displayName~rename(name),dimensionItemType&order=displayName:asc&filter=dimensionItemType:eq:PROGRAM_INDICATOR&filter=programId:eq:${prog.id}&paging=true&page=1`,
                                    {
                                        headers: {
                                            "Authorization": `Basic ${props.access_token}`
                                        }
                                    }).then((response)=>{
                                        let children=[]
                                        if(response.data.dataItems.length > 0){
                                            response.data.dataItems.forEach((item)=>{
                                                children.push({
                                                    label:item.name,
                                                    id:item.id,
                                                    level:'child'
                                                })
                                            });
                                        }
                                    temp.push({
                                        "label":prog.displayName,
                                        "id":prog.id,
                                        level:"group",
                                        children: children
                                    })
                                    resolve();
                                })
                            })

                        )
                    })
                    Promise.all(promises).then(() => {
                        setPrograms(temp)
                    });

                }
                //setDEGroups(response.data.dataElementGroups)
            }
        })
    }

    useEffect(()=>{
        fetchPrograms()
    },[]);

    const onChange = (currentNode, selectedNodes) => {
        let progIndicators = [];
        progIndicators.push(..._.map(_.filter(selectedNodes, {level:'child'}), 'id'))
        _.map(_.filter(selectedNodes, {level:'group'}), '_children').forEach(dx =>{
            progIndicators.push(...dx)
        });

        props.setProgINDParam(progIndicators.length > 0?'&dimension=dx:'+_.join(progIndicators,';'):null);
    };

    const DropDownTreeSelect = useMemo(() => {
        return (
            <DropdownTreeSelect
                data={programs}
                onChange={onChange}
                className="bootstrap-demo"
                showDropdown="initial"
            />
        )
    }, [programs])


    return (
        <div className={classes.root}>
            {DropDownTreeSelect}
        </div>
    );
}

export default Programindicatorsgroup;
