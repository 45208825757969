import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from "axios";
import DropdownTreeSelect from "react-dropdown-tree-select";
import 'react-dropdown-tree-select/dist/styles.css'
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import _ from 'lodash';

//override css
const useStyles = makeStyles(theme => ({
    root: {
        border:'2px solid #eee',
        padding:'5px',
        '& .react-dropdown-tree-select .dropdown .dropdown-content': {
            position:'relative',
            marginTop:'-410px',
            width:'470px',
            padding:'10px'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger':{
            marginLeft:'520px',
            width:'400px',
            minHeight:'400px'
        },
        '& .tag-item .search':{
           display:'none'
        },
        '& .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after':{
            display:'none'
        }
    },
}))
function Dataelementgroups(props) {
    const classes = useStyles()
    const [deGroups, setDEGroups] = useState([]);



    const assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach(k => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                assignObjectPaths(node, node.path);
            }
        });
    };



    const fetchDataelementGroups = () =>{
        axios.get(props.base_url+'/api/dataElementGroups.json?fields=id,displayName,path,children::isNotEmpty,memberCount,dataElements[name,id]&paging=false',
            {
                headers: {
                    "Authorization": `Basic ${props.access_token}`
                }
            }).then((response)=>{
            if(response.status === 200){
                console.log(response)

                if(response.data.dataElementGroups.length > 0){
                    let temp = [];
                    response.data.dataElementGroups.forEach(group =>{
                        let children = [];
                        group.dataElements.forEach(child =>{
                            children.push({
                                label:child.name,
                                id:child.id,
                                level:'child'
                            })
                        })
                        temp.push({
                            "label":group.displayName,
                            "id":group.id,
                            level:"group",
                            children: children
                        })
                    })
                    console.log(temp)
                    setDEGroups(temp)
                }
                //setDEGroups(response.data.dataElementGroups)
            }
        })
    }

    useEffect(()=>{
        fetchDataelementGroups()
    },[]);


    const onChange = (currentNode, selectedNodes) => {
        let deGroupParam =[]
        let deParam =  _.map(_.filter(selectedNodes, {level:'child'}), 'id');
        _.map(_.filter(selectedNodes, {level:'group'}), 'id').forEach(dx =>{
            deGroupParam.push('DE_GROUP-'+dx)
        });

        props.setDeGroupParam(deGroupParam.length > 0?'&dimension=dx:'+_.join(deGroupParam,';'):null);
        props.setDEParam(deParam.length > 0?'&dimension=dx:'+_.join(deParam,';'):null);
    };


    const DropDownTreeSelect = useMemo(() => {
        return (
            <DropdownTreeSelect
                data={deGroups}
                onChange={onChange}
                className="bootstrap-demo"
                showDropdown="initial"
            />
        )
    }, [deGroups])

    return (
        <div className={classes.root}>
            {DropDownTreeSelect}
        </div>
    );
}


export default Dataelementgroups;
