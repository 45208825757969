 const schema_columns = ()=>{
    return [{
         id: "periodid",
         alias: "periodid",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "periodname",
         alias: "periodname",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "periodcode",
         alias: "periodcode",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "organisationunitid",
         alias: "organisationunitid",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "organisationunitname",
         alias: "organisationunitname",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "dataid",
         alias: "dataid",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "dataname",
         alias: "dataname",
         dataType: window.tableau.dataTypeEnum.string
     }, {
         id: "Total",
         alias: "Total",
         dataType: window.tableau.dataTypeEnum.float
     }]
}

 export default schema_columns;
